<template>
	<b-row>
		<b-col sm="12" md="12" lg="6" xl="6">
			<b-form-group
				:label="FormMSG(1, 'Department')"
				:label-cols="`${burgerField ? 0 : 12}`"
				:label-cols-md="`${burgerField ? 0 : 3}`"
				label-for="new-expense_ticket-department"
			>
				<b-form-select
					id="new-expense_ticket-department"
					v-model="selectedDepartment"
					:options="mapDepartments"
					@change="departmentChange"
					:disabled="isEditable() === false"
					:class="{ 'is-invalid': isSubmitted && $v.selectedDepartment.$error }"
				/>
				<div v-if="isSubmitted && $v.selectedDepartment.$error" class="invalid-feedback">
					{{ FormMSG(3, 'Please, choose a department') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col sm="12" md="12" lg="6" xl="6">
			<b-form-group
				:label="FormMSG(2, 'Function')"
				:label-cols="`${burgerField ? 0 : 12}`"
				:label-cols-md="`${burgerField ? 0 : 3}`"
				label-for="new-function"
			>
				<div
					:style="{
						display: 'flex',
						flexDirection: $screen.width > 576 ? 'row' : 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						gap: '6px'
					}"
				>
					<b-button
						v-if="useMemoDeal"
						size="sm"
						class="btn-custom-ctp w-25"
						:class="{ 'w-25': $screen.width > 576 ? true : false, 'w-100': $screen.width > 576 ? false : true }"
						variant="outline-primary"
						:disabled="selectedFunction && mapFunctions.length === 1"
						@click.prevent.stop="handleLoadContractTemplate"
						:id="`contract-template-${generateKey}`"
					>
						<div v-if="isLoadingContractTemplate">{{ FormMSG(150, 'Memo deal already loaded') }}</div>
						<div v-else>{{ FormMSG(151, 'Load memo deal template') }}</div>
					</b-button>
					<b-form-select
						id="new-function"
						v-model="selectedFunction"
						:options="mapFunctions"
						@change="(e) => functionChange(e)"
						:disabled="isEditable() === false || mapFunctions.length === 0"
						:class="{
							'is-invalid': isSubmitted && $v.selectedFunction.$error,
							'w-75': useMemoDeal && $screen.width > 576 ? true : false,
							'w-100': !useMemoDeal && $screen.width > 576 ? false : true
						}"
					/>
					<div v-if="isSubmitted && $v.selectedFunction.$error" class="invalid-feedback">
						{{ FormMSG(4, 'Please, choose a function') }}
					</div>
				</div>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script>
// import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import { isNil, greaterThanZero } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import GlobalMixin from '@/mixins/global.mixin';
import { required, decimal } from 'vuelidate/lib/validators';
import { getFunctions } from '@/cruds/department.crud';

export default {
	name: 'DepartmentFunctionSelection',
	mixins: [languageMessages, GlobalMixin, validationMixin],
	props: {
		generateKey: {
			type: Number,
			required: false,
			default: 1
		},
		useMemoDeal: {
			type: Boolean,
			required: false,
			default: false
		},
		department: {
			type: Number,
			required: false,
			default: null
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		disableEdit: {
			default: false,
			require: false,
			type: Boolean
		},
		burgerField: {
			default: false,
			require: false,
			type: Boolean
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		fromJobRequest: { type: Boolean, default: false, required: false },
		refreshList: { type: Boolean, default: false, required: false }
	},
	data() {
		return {
			selectedDepartment: null,
			selectedFunction: null,
			allFunctions: [],
			elemForm: {
				department: this.department,
				function: 0
			},
			contractTemplateStatus: false,
			isLoadingContractTemplate: false
		};
	},
	async mounted() {
		await this.initData();
	},
	computed: {
		...mapGetters({
			allDepartements: 'expenses/departmentsForSalary',
			currentJobRequest: 'job-request/currentJobRequest'
		}),
		mapDepartments() {
			const departments = this.allDepartements.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			// return departments;
			var txt = this.FormMSG(22, 'Select a department');
			return [{ value: 0, text: txt }, ...departments];
			// return [{ value: null, text: "Select a department" }, ...departments];
		},
		mapFunctions() {
			const functions =
				this.allFunctions && this.allFunctions.length > 0
					? this.allFunctions.map((c) => ({
							value: c.value,
							text: c.message,
							category: c.category
					  }))
					: [];
			const txtSelFunc = this.FormMSG(23, 'Select a function');
			const txtWaiting = this.FormMSG(24, 'Select a department first');
			const text = isNil(this.selectedDepartment) ? txtWaiting : txtSelFunc;
			//  ? "Waiting..."
			//  : "Select a Function";
			return [{ value: 0, text }, ...functions];
		}
	},
	watch: {
		refreshList: {
			async handler(newVal) {
				if (newVal === true) {
					await this.initData();

					this.$emit('department-function-selection:init-finish');
				}
			},
			immediate: true,
			deep: true
		},
		isSubmitted: {
			handler(n) {
				if (!isNil(n) && n === true) {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.$emit('department-function:selector:invalid', true);
					} else {
						this.$emit('department-function:selector:invalid', false);
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		async initData() {
			if (this.fromJobRequest === true) {
				this.$store.dispatch('expenses/getDepartmentsForSalaryJobRequest', +this.currentJobRequest.targetProjectId);
			} else {
				this.$store.dispatch('expenses/getDepartmentsForSalary');
			}

			await this.initSelectedDepartment();
		},
		handleLoadContractTemplate() {
			this.isLoadingContractTemplate = !this.isLoadingContractTemplate;
			this.$emit('active-template:status', this.isLoadingContractTemplate);
			this.isLoadingContractTemplate && this.$emit('department-function-selector:functionSelected', this.elemForm);
		},
		setChangeTemplateStatus(e) {
			this.$emit('active-template:status', e);
		},
		isEditable() {
			// console.log("isEditable:",!this.disableEdit)
			return !this.disableEdit;
		},
		async initSelectedDepartment() {
			if (!isNil(this.editData)) {
				await this.getFunctions(this.editData.department);
				this.selectedDepartment = this.editData.department;
				this.selectedFunction = this.editData.function;
				this.elemForm.function = parseInt(this.editData.function);
				this.elemForm.department = parseInt(this.editData.department);
				//console.log("2 initSelectedDepartment this.editData:",this.editData);
			} else if (!isNil(this.department)) {
				//console.log("3 initSelectedDepartment this.department:",this.department);
				this.selectedDepartment = parseInt(this.department);
				this.departmentChange(this.department);
			} else {
				const { department } = store.state.myProfile;
				this.selectedDepartment = parseInt(department);
				this.departmentChange(department);
				//console.log("4 initSelectedDepartment department from profile:",department);
			}
		},
		async setAutoSelectElement() {
			// console.log("1 setAutoSelectElement this.editData:", this.editData);
			if (!isNil(this.editData.department)) {
				//  console.log("setAutoSelectElement editData function:",this.editData.function);
				//  const { Dep, Fun } = this.editData;
				await this.departmentChange(this.editData.department);
				this.selectedDepartment = this.editData.department;
				this.selectedFunction = this.editData.function;
				//    console.log("setAutoSelectElement this.selectedFunction:",this.selectedFunction);
			}
		},
		// Handle selection changes
		async departmentChange(departementID) {
			//  console.log("departmentChange;",departementID);
			await this.getFunctions(departementID);
			this.selectedFunction = 0;
			this.elemForm.function = 0; // reset function
			this.elemForm.department = parseInt(departementID);
			this.$emit('department-function-selector:departmentSelected', this.elemForm.department);

			this.$v.$touch();
			if (this.$v.$invalid) {
				this.$emit('department-function:selector:invalid', true);
			} else {
				this.$emit('department-function:selector:invalid', false);
			}
		},
		functionChange(functionID) {
			// console.log("functionChange;",functionID);
			let funcData = this.allFunctions.find((option) => option.value === functionID);
			this.elemForm.category = funcData ? parseInt(funcData.category, 10) : 0;
			this.elemForm.function = parseInt(functionID);
			this.$emit('department-function-selector:functionSelected', this.elemForm);

			this.$v.$touch();
			if (this.$v.$invalid) {
				this.$emit('department-function:selector:invalid', true);
			} else {
				this.$emit('department-function:selector:invalid', false);
			}
		},
		async getFunctions(id) {
			this.allFunctions = await getFunctions(id, this.fromJobRequest === true ? +this.currentJobRequest.targetProjectId : null);
			// console.log(this.allFunctions);
		}
	},
	validations: {
		selectedDepartment: {
			required,
			decimal,
			min: greaterThanZero
		},
		selectedFunction: {
			required,
			decimal,
			min: greaterThanZero
		}
	}
};
</script>
<style lang="scss">
.gap-2 {
	gap: 4px;
}
.btn-custom-ctp {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	border-radius: 8px !important;
	height: calc(1.5em + 0.75rem + 2px);
	font-size: 11px;
}
</style>
