var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(1, "Department"),
                "label-cols": `${_vm.burgerField ? 0 : 12}`,
                "label-cols-md": `${_vm.burgerField ? 0 : 3}`,
                "label-for": "new-expense_ticket-department",
              },
            },
            [
              _c("b-form-select", {
                class: {
                  "is-invalid":
                    _vm.isSubmitted && _vm.$v.selectedDepartment.$error,
                },
                attrs: {
                  id: "new-expense_ticket-department",
                  options: _vm.mapDepartments,
                  disabled: _vm.isEditable() === false,
                },
                on: { change: _vm.departmentChange },
                model: {
                  value: _vm.selectedDepartment,
                  callback: function ($$v) {
                    _vm.selectedDepartment = $$v
                  },
                  expression: "selectedDepartment",
                },
              }),
              _vm.isSubmitted && _vm.$v.selectedDepartment.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(3, "Please, choose a department")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(2, "Function"),
                "label-cols": `${_vm.burgerField ? 0 : 12}`,
                "label-cols-md": `${_vm.burgerField ? 0 : 3}`,
                "label-for": "new-function",
              },
            },
            [
              _c(
                "div",
                {
                  style: {
                    display: "flex",
                    flexDirection: _vm.$screen.width > 576 ? "row" : "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "6px",
                  },
                },
                [
                  _vm.useMemoDeal
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-custom-ctp w-25",
                          class: {
                            "w-25": _vm.$screen.width > 576 ? true : false,
                            "w-100": _vm.$screen.width > 576 ? false : true,
                          },
                          attrs: {
                            size: "sm",
                            variant: "outline-primary",
                            disabled:
                              _vm.selectedFunction &&
                              _vm.mapFunctions.length === 1,
                            id: `contract-template-${_vm.generateKey}`,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.handleLoadContractTemplate.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm.isLoadingContractTemplate
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(150, "Memo deal already loaded")
                                  )
                                ),
                              ])
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(151, "Load memo deal template")
                                  )
                                ),
                              ]),
                        ]
                      )
                    : _vm._e(),
                  _c("b-form-select", {
                    class: {
                      "is-invalid":
                        _vm.isSubmitted && _vm.$v.selectedFunction.$error,
                      "w-75":
                        _vm.useMemoDeal && _vm.$screen.width > 576
                          ? true
                          : false,
                      "w-100":
                        !_vm.useMemoDeal && _vm.$screen.width > 576
                          ? false
                          : true,
                    },
                    attrs: {
                      id: "new-function",
                      options: _vm.mapFunctions,
                      disabled:
                        _vm.isEditable() === false ||
                        _vm.mapFunctions.length === 0,
                    },
                    on: { change: (e) => _vm.functionChange(e) },
                    model: {
                      value: _vm.selectedFunction,
                      callback: function ($$v) {
                        _vm.selectedFunction = $$v
                      },
                      expression: "selectedFunction",
                    },
                  }),
                  _vm.isSubmitted && _vm.$v.selectedFunction.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(4, "Please, choose a function")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }